import {
  bulkUpdateNotificationStatusByFilter,
  countViewerFeedNotifications,
  queryViewerFeedNotifications,
} from '@wix/ambassador-notifications-members-feed-v1-members-viewer-feed-notification/http';
import {
  Channel,
  DeeplinkType,
  IconType,
  NotificationType,
  SortOrder,
  Status,
} from '@wix/ambassador-notifications-members-feed-v1-members-viewer-feed-notification/types';
import type { OOIControllerFlowAPI } from '@wix/yoshi-flow-editor-runtime/build/cjs/flow-api/OOIController';
import Chance from 'chance';

const chance = new Chance();

export const markAsRead = (flowAPI: OOIControllerFlowAPI, id: string) => {
  return flowAPI.httpClient.request(
    bulkUpdateNotificationStatusByFilter({
      filter: {
        id: { $in: [id] },
      },
      status: Status.READ,
    }),
  );
};

export const markAllAsRead = (flowAPI: OOIControllerFlowAPI) => {
  return flowAPI.httpClient.request(
    bulkUpdateNotificationStatusByFilter({
      filter: {
        channel: Channel.WEB,
        status: { $in: [Status.UNSEEN, Status.SEEN_BUT_UNREAD] },
        sentDate: { $lte: new Date() },
      },
      status: Status.READ,
    }),
  );
};

export const getUnseenCount = async (flowAPI: OOIControllerFlowAPI) => {
  if (shouldGetDummyData(flowAPI)) {
    return 2;
  }
  const { data } = await flowAPI.httpClient.request(
    countViewerFeedNotifications({
      filter: {
        channel: Channel.WEB,
        status: Status.UNSEEN,
      },
      maxCount: 100,
    }),
  );
  return data.count;
};

export const getNotifications = async (
  flowAPI: OOIControllerFlowAPI,
  cursor?: string,
) => {
  if (shouldGetDummyData(flowAPI)) {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    return getDummyNotifications();
  }
  const { data } = await flowAPI.httpClient.request(
    queryViewerFeedNotifications({
      query: {
        filter: cursor
          ? undefined
          : {
              channel: Channel.WEB,
            },
        sort: cursor
          ? undefined
          : [
              {
                fieldName: 'sentDate',
                order: SortOrder.DESC,
              },
            ],
        cursorPaging: {
          limit: 50,
          cursor,
        },
      },
    }),
  );
  return data;
};

const shouldGetDummyData = (flowAPI: OOIControllerFlowAPI) =>
  flowAPI.environment.isEditor || flowAPI.environment.isPreview;

const getDummyNotifications = () => {
  return {
    viewerFeedNotifications: [
      {
        id: chance.guid(),
        type: NotificationType.REGULAR,
        metaSiteId: 'f1adf39a-4283-41fb-bb56-b74eb7278432',
        channel: Channel.WEB,
        title: 'New Blog Post Like',
        message: 'John Smith likes your post "some post".',
        action: 'View Post',
        sentDate: new Date(),
        status: Status.UNSEEN,
        appMetadata: {
          tpa: false,
          name: 'Wix Blog',
          appId: '14bcded7-0066-7c35-14d7-466cb3f09103',
        },
        deeplink: {
          type: DeeplinkType.WEB_URL,
          webUrlData: {
            webUrl: 'https://wix.com',
          },
        },
        icon: {
          type: IconType.PERSON_AVATAR,
          personAvatarData: {
            data: 'https://lh3.googleusercontent.com/a/ACg8ocLqDyAb-qHmo0MXYv__ADg3bppgv7d2-irDmqMYXARSxeOPDDhl%3Ds96-c',
          },
        },
        memberId: '5566bd08-e3b2-4f47-b341-9f4701492293',
      },
      {
        id: chance.guid(),
        type: NotificationType.REGULAR,
        metaSiteId: 'f1adf39a-4283-41fb-bb56-b74eb7278432',
        channel: Channel.WEB,
        title: 'New Blog Post Like',
        message: 'Chew Chang likes your post "some post".',
        action: 'View Post',
        sentDate: new Date(),
        status: Status.UNSEEN,
        appMetadata: {
          tpa: false,
          name: 'Wix Blog',
          appId: '14bcded7-0066-7c35-14d7-466cb3f09103',
        },
        deeplink: {
          type: DeeplinkType.WEB_URL,
          webUrlData: {
            webUrl: 'https://wix.com',
          },
        },
        icon: {
          type: IconType.PERSON_AVATAR,
          personAvatarData: {
            data: 'https://lh3.googleusercontent.com/a/ACg8ocLqDyAb-qHmo0MXYv__ADg3bppgv7d2-irDmqMYXARSxeOPDDhl%3Ds96-c',
          },
        },
        memberId: '5566bd08-e3b2-4f47-b341-9f4701492293',
      },
      {
        id: chance.guid(),
        type: NotificationType.REGULAR,
        metaSiteId: 'f1adf39a-4283-41fb-bb56-b74eb7278432',
        channel: Channel.WEB,
        title: 'New Blog Post Like',
        message: 'Brad Pitt likes your post "some post".',
        action: 'View Post',
        sentDate: new Date(),
        status: Status.READ,
        appMetadata: {
          tpa: false,
          name: 'Wix Blog',
          appId: '14bcded7-0066-7c35-14d7-466cb3f09103',
        },
        deeplink: {
          type: DeeplinkType.WEB_URL,
          webUrlData: {
            webUrl: 'https://wix.com',
          },
        },
        icon: {
          type: IconType.PERSON_AVATAR,
          personAvatarData: {
            data: 'https://lh3.googleusercontent.com/a/ACg8ocLqDyAb-qHmo0MXYv__ADg3bppgv7d2-irDmqMYXARSxeOPDDhl%3Ds96-c',
          },
        },
        memberId: '5566bd08-e3b2-4f47-b341-9f4701492293',
      },
    ],
    pagingMetadata: {
      count: 3,
      cursors: {},
      hasNext: false,
    },
  };
};
