import { CreateControllerFn } from '@wix/yoshi-flow-editor';
import {
  getNotifications,
  getUnseenCount,
  markAllAsRead,
  markAsRead,
} from '../../apis/members-feed/members-feed';

const createController: CreateControllerFn = async ({
  flowAPI,
  controllerConfig,
}) => {
  const [notificationsRes, unseenCount] = await Promise.all([
    getNotifications(flowAPI),
    getUnseenCount(flowAPI),
  ]);
  return {
    async pageReady() {
      controllerConfig.setProps({
        notificationsRes,
        unseenCount,
        markAllAsRead: () => markAllAsRead(flowAPI),
        markAsRead: (id: string) => markAsRead(flowAPI, id),
        getNextPage: async (cursor: string) => {
          const nextNotificationsRes = await getNotifications(flowAPI, cursor);
          controllerConfig.setProps({
            notificationsRes: nextNotificationsRes,
          });
        },
        fitToContentHeight: true,
      });
    },
  };
};

export default createController;
